// extracted by mini-css-extract-plugin
export var articlesGrid = "style-module--articlesGrid--7c75d";
export var container = "style-module--container--3f0d5";
export var featuredSlider = "style-module--featuredSlider--86e73";
export var h1 = "style-module--h1--9896e";
export var h2 = "style-module--h2--b70f1";
export var hero = "style-module--hero--e2b60";
export var loadMoreButton = "style-module--loadMoreButton--62d97";
export var loadMoreContainer = "style-module--loadMoreContainer--4cd56";
export var slider = "style-module--slider--ca7e9";
export var smallText = "style-module--smallText--d3a22";
export var textPadding = "style-module--textPadding--2aa58";