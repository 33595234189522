import Card from '../Card'
import CardBlogPost from '../CardBlogPost'
import SnappySlider from '../SnappySlider'
import StickyBar from '../StickyBar'
import Template from '../Template'
import FurSaverHero from './1.jpg'
import BiteworkHero from './2.jpg'
import Nutrition1 from './3.jpg'
import Training1 from './4.jpg'
import Training2 from './5.jpg'
import Training3 from './6.jpg'
import Training4 from './7.jpg'
import Training5 from './8.jpg'
import Nutrition2 from './9.jpg'
import Nutrition3 from './10.jpg'
import Nutrition4 from './11.jpg'
import Nutrition5 from './12.jpg'
import Training6 from './13.jpg'
import * as style from './style.module.scss'
import * as React from 'react'
import { Helmet } from 'react-helmet'

const POSTS_PER_PAGE = 10

export default class PageBlogPosts extends React.Component {
  state = {
    sliderIndex: 0,
    displayedPosts: POSTS_PER_PAGE,
  }

  handleLoadMore = () => {
    this.setState((prevState) => ({
      displayedPosts: prevState.displayedPosts + POSTS_PER_PAGE,
    }))
  }

  render() {
    const { articles } = this.props.data
    const { displayedPosts } = this.state
    const hasMorePosts = displayedPosts < articles.edges.length

    return (
      <Template>
        <div className={style.container}>
          <Helmet>
            <title>Articles - Doggos.com</title>
          </Helmet>

          <div className={style.hero}>
            <h1>Knowledgebase</h1>
            <h2>
              Learn about dogs for <strong>free</strong>! Browse our
              professionally edited articles on a range of topics.
            </h2>
          </div>
          <div className={style.featuredSlider}>
            <Card
              link={'/fur-saver-collar'}
              image={FurSaverHero}
              title={"What's a fur saver collar?"}
              subline={'Featured'}
            />
            <Card
              link={'/can-i-train-my-own-dog-in-bitework'}
              image={BiteworkHero}
              title={'Can I train my own dog in bitework?'}
              subline={'Featured'}
            />
          </div>

          <div>
            <div>
              <StickyBar>
                <h2 className={style.h2}>Dog training</h2>
              </StickyBar>
              <div className={style.slider}>
                <CardBlogPost
                  link={'/what-is-mondioring'}
                  image={Training6}
                  title={'What is Mondioring?'}
                  subline={'Working dogs'}
                />
                <CardBlogPost
                  link={'/how-to-teach-your-dog-to-heel'}
                  image={Training1}
                  title={'How to teach your dog to heel'}
                  subline={'Obedience'}
                />
                <CardBlogPost
                  link={'/is-it-ok-to-use-a-retractable-dog-leash'}
                  image={Training2}
                  title={'Is it OK to use a retractable dog leash?'}
                  subline={'Gear'}
                />
                <CardBlogPost
                  link={'/choosing-a-ball-on-string-for-your-dog'}
                  image={Training3}
                  title={'Choosing a ball on string for your dog'}
                  subline={'Gear'}
                />
                <CardBlogPost
                  link={'/whats-an-agitation-harness'}
                  image={Training4}
                  title={"What's an agitation harness?"}
                  subline={'Gear'}
                />
              </div>
            </div>

            <div>
              <StickyBar>
                <h2 className={style.h2}>Nutrition</h2>
              </StickyBar>
              <div className={style.slider}>
                <CardBlogPost
                  link={'/why-feed-your-dog-a-raw-diet'}
                  subline={'Raw diet'}
                  image={Nutrition1}
                  title={'Why feed your dog a raw diet?'}
                />
                <CardBlogPost
                  link={'/transitioning-your-dog-or-puppy-to-a-raw-diet'}
                  subline={'Raw diet'}
                  image={Nutrition2}
                  title={'Transitioning your dog to a raw diet'}
                />
                <CardBlogPost
                  link={'/adding-veggies-to-a-raw-diet'}
                  subline={'Raw diet'}
                  image={Nutrition3}
                  title={'Adding veggies to a raw diet'}
                />
                <CardBlogPost
                  link={'/feeding-dogs-with-pancreatitis'}
                  subline={'Health'}
                  image={Nutrition4}
                  title={'Feeding dogs with pancreatitis'}
                />
                <CardBlogPost
                  link={'/insect-based-dog-food'}
                  subline={'Sustainable dog food'}
                  image={Nutrition5}
                  title={'Insect based dog food'}
                />
              </div>
            </div>
            {/* The rest of the articles */}
            <div>
              <StickyBar>
                <h2 className={style.h2}>Read more</h2>
              </StickyBar>
              <div className={style.articlesGrid}>
                {articles.edges
                  .slice(1, displayedPosts + 1)
                  .map((edge, index) => {
                    const article = edge.node.frontmatter
                    return (
                      <Card
                        key={article.id}
                        link={{
                          pathname: `/${article.id}/`,
                          state: { offset: index },
                        }}
                        subline={article.tags_for_display}
                        image={article.image}
                        title={article.postTitle}
                      />
                    )
                  })}
              </div>
              {hasMorePosts && (
                <div className={style.loadMoreContainer}>
                  <button
                    className={style.loadMoreButton}
                    onClick={this.handleLoadMore}
                  >
                    Load more
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Template>
    )
  }
}
